import { onCLS, onFID, onINP, onLCP, onFCP, onTTFB } from 'web-vitals';

const pageLoadTime = Date.now();
const reportingEndpoint =
  'https://default.bbc-reporting-api.app/report-endpoint';
const randomNumber = Math.random();
const sampleRate = 0.1;
const chosen = randomNumber <= sampleRate;

const ux = document.currentScript.getAttribute('data-ux');
const release = document.currentScript.getAttribute('data-release');

(async function run() {
  // Function scope avoids top-level await which Webpack complains about
  const consent = await window.bbcuser.allowsPerformanceCookies();
  if (reportingEndpoint && navigator.sendBeacon && chosen && consent) {
    const vitals = {
      cls: null,
      fid: null,
      inp: null,
      lcp: null,
      fcp: null,
      ttfb: null,
    };

    const update = ({ name, value }) => {
      const vitalName = name.toLowerCase();
      vitals[vitalName] = value;
    };

    onCLS(update, true); // Setting 'true' will report all CLS changes
    onFID(update);
    onINP(update);
    onLCP(update, true); // Setting 'true' will report all LCP changes
    onFCP(update);
    onTTFB(update);

    window.addEventListener('pagehide', async () => {
      const destination = await window.bbcpage.getDestination();
      const contentType = await window.bbcpage.getContentType();

      // This only measure people who have opted in to Orbit setting BBC Reith
      // for them. It will falsely flag '0' for products that do use Reith
      // but loaded it themselves.
      // We can sift those back out during analysis thought as the key one is
      // that it definitely finds all page views to a page not opted in.
      const reith = document.documentElement.className.includes(
        'b-reith-sans-font',
      )
        ? '1'
        : '0';

      const reportingUrl = `${reportingEndpoint}?platform=orbit-${ux}-${release}&owner=${destination}&pageType=${contentType}&reith=${reith}`;

      const beaconJson = [
        {
          type: 'web-vitals',
          url: window.location.href,
          age: Date.now() - pageLoadTime,
          body: { ...vitals },
        },
      ];

      const beacon = JSON.stringify(beaconJson);

      const headers = { type: 'application/reports+json' };

      const blob = new Blob([beacon], headers);
      navigator.sendBeacon(reportingUrl, blob);
    });
  }
})();
